define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/customerScreens/orderScreen/orderInformation/totals',

  'modules/shop.cash-register-retail/collections/currentOrderItem',
  'modules/common/components/currency',
], (
  $, _, Backbone, Template,
  OrderItemCollection,
  Currency,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'customer-screen-totals',

  onShow() {
    OrderItemCollection.on('all', this.render, this);
  },

  onDestroy() {
    OrderItemCollection.off('all', this.render, this);
  },

  serializeData() {
    const totalWt = OrderItemCollection.getTotalPriceWt();
    const totalDiscountWt = Math.abs(OrderItemCollection.getTotalDiscountWt());

    return {
      subtotal: Currency.Math.add(totalWt, Currency.toCurrency(totalDiscountWt)),
      total_wt: totalWt,
      show_total_discount_wt: totalDiscountWt > 0,
      total_discount_wt: totalDiscountWt,
    };
  },

}));
