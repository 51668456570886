define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/customers/list/collection',

  'modules/shop.cash-register-retail/views/customers/list/item',
  'modules/common/views/swappableWithLoading',

  'upx.modules/BillingModule/models/InvoiceStat',

  'modules/shop.cash-register-retail/components/toaster',
], (
  $, _, Backbone, Template,
  ItemView, Swappable,
  InvoiceStatModel,
  Toaster,
) => Backbone.Marionette.CompositeView.extend({

  template: Template,

  className: 'customer-list',

  childView: ItemView,

  onAddChild(view) {
    (new Swappable()).proxySwappableEvents(view, this);
  },

  childViewOptions(model) {
    return {
      model,
      resultDef: this.options.resultDef,
    };
  },

  childViewContainer: 'tbody',

  events: {
    'click [data-action="more"]': 'moreClicked',
  },

  ui: {
    more: '[data-ui="more"]',
  },

  moreClicked() {
    if (this.collection.canFetchNext) {
      this.triggerMethod('loader:show');
      this.collection.fetchNext()
        .always((resp) => {
          const { data } = resp;
          this.showMoreCheck();
          const relationDataIds = _.pluck(data, 'id');
          this.fetchInvoiceTotals(relationDataIds)
            .always(() => this.triggerMethod('loader:hide'));
        });
    }
  },

  onShow() {
    this.triggerMethod('loader:show');
    const relationDataIds = this.collection.pluck('id');
    this.fetchInvoiceTotals(relationDataIds)
      .always(() => this.triggerMethod('loader:hide'));
  },

  fetchInvoiceTotals(relationDataIds = []) {
    const def = new $.Deferred();
    const invoiceStatModel = new InvoiceStatModel();
    const parameters = {
      start: 0,
      limit: 0,
      filters: [{
        name: 'relation_data_to_id__in_list',
        multi_val: relationDataIds,
      }],
    };

    invoiceStatModel.listByRelationTo(parameters)
      .fail((totalsResp) => Toaster.error(totalsResp.error))
      .then((resp) => {
        const { data } = resp;
        data.forEach((item) => {
          const { relation_data_to_id } = item;
          const model = this.collection.get(relation_data_to_id);
          if (model) {
            model.set('outstanding_invoice_stats', item);
          }
        });
        def.resolve();
      }, def.reject);

    return def;
  },

  showMoreCheck() {
    if (this.collection.canFetchNext()) {
      this.$el.find('tfoot').show();
    } else {
      this.$el.find('tfoot').hide();
    }
  },

  onRender() {
    this.showMoreCheck();
  },

}));
