define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/customerScreens/orderScreen/orderInformation/collectionItem',

  'modules/common/components/currency',
], (
  $, _, Backbone, Template,
  Currency,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  tagName: 'tr',

  modelEvents: {
    change: 'render',
  },

  initialize() {
    if (!this.model.has('price_wt')) {
      this.model.calculateTotalPrices();
    }
  },

  getPpuAfterDiscountWt() {
    const discountPpuWt = Currency.toCurrency(this.model.get('discount_ppu_wt'));
    const ppuWt = Currency.toCurrency(this.model.get('ppu_wt'));
    const discountWt = Currency.Math.subtract(ppuWt, discountPpuWt);
    return Currency.currencyToFloat(discountWt);
  },

  serializeData() {
    const data = this.model.toJSON();
    data.ppuAfterDiscountWt = this.getPpuAfterDiscountWt();
    return data;
  },

}));
