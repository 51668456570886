define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/customers/view.hbs',
  'modules/common/collections/countries',
  'modules/common/collections/languages',

  'modules/shop.cash-register-retail/models/customerScreenData',
  'modules/common/components/locale',
  'modules/upx/components/upx',
  'modules/shop.cash-register-retail/components/toaster',
  'modules/shop.cash-register-retail/components/feature',

  'modules/shop.cash-register-retail/views/popups/customerOrderListPopup',
  'modules/shop.cash-register-retail/views/orders/layout',

  'modules/admin/behaviors/loader',
  'modules/shop.common/components/mode',
  'modules/common/components/historyBreadcrumb',
  'modules/shop.cash-register-retail/views/customers/changeDiscount',
  'modules/shop.cash-register-retail/models/selectedCustomer',
], (
  $, _, Backbone, Template,
  CountryCollection, LanguageCollection,
  CustomerScreenData, Locale, Upx, Toaster, Feature,
  CustomerOrderListPopup, OrderView,
  Loader, Mode, HistoryBreadcrumb, ChangeDiscountPopup, SelectedCustomer,
) => Backbone.Marionette.LayoutView.extend({
  template: Template,

  className: 'customer-view',

  events: {
    'click [data-action="outstanding"]': 'outstandingClicked',
    'click [data-action="edit"]': 'editClicked',
    'click [data-action="back"]': 'backClicked',
    'click [data-action="display"]': 'displayClicked',
    'click [data-action="order-list"]': 'searchOrders',
    'click [data-action="loyalty"]': 'loyaltyClicked',
    'click [data-action="loyalty-join"]': 'loyaltyJoinClicked',
    'click [data-action="change-discount"]': 'changeDiscountClicked',
  },

  regions: {
    popup: '[data-region="popup"]',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  onDestroy() {
    // Make sure that the customer data will not be visible
    // on the second screen by removing it when this view gets destroyed
    CustomerScreenData.removeCustomerInformation();
  },

  backClicked() {
    HistoryBreadcrumb.goBack({ skipSameRoute: false });
  },

  loyaltyClicked() {
    this.triggerMethod('layout:swap', 'loyalty');
  },

  loyaltyJoinClicked() {
    const def = this.loader.startLoader();
    Upx.call('ShopModule', 'newLoyaltyCustomer',
      {
        fields: {
          loyalty_program_id: this.model.get('loyalty_program_id'),
          relation_data_id: this.model.get('id'),
        },
      }).then(
      () => {
        Toaster.info(Locale.translate('customer_joined_the_program'));
        this.loyaltyClicked();
        def.resolve();
      },
      () => {
        Toaster.error(Locale.translate('failed_to_join_the_program'));
        def.resolve();
      },
    );
  },

  editClicked() {
    this.triggerMethod('layout:swap', 'edit');
  },

  outstandingClicked() {
    this.triggerMethod('swappable:reloadViewStorage', 'outstanding');
    this.triggerMethod('layout:swap', 'outstanding');
  },

  displayClicked: _.debounce(function () {
    CustomerScreenData.displayCustomerInformation(this.model);
    Toaster.info(Locale.translate('data_was_shown_on_the_customer_screen'));
    this.$el.find('[data-action="display"]').prop('disabled', true);
  }, 100),

  serializeData() {
    const data = this.model.toJSON();

    const countryModel = CountryCollection.findWhere({ iso2: this.model.get('contact_address.country_iso2') });
    if (countryModel) {
      data.country = countryModel.get('name');
    }

    const languageModel = LanguageCollection.findWhere({ iso2: this.model.get('language_iso2') });
    if (languageModel) {
      data.language = languageModel.get('name');
    }

    let ismale;
    switch (this.model.get('contact_person.ismale')) {
      case true:
      case '1':
        ismale = 'male';
        break;
      case false:
      case '0':
        ismale = 'female';
        break;
      default:
        ismale = 'unknown';
        break;
    }

    data.contact_person.ismale = ismale;
    data.isInWebOrAppMode = Mode.isInWebMode() || Mode.isInAppMode();
    data.isInvoiceFeatureEnabled = Feature.getFeatureActiveByAppName(
      Feature.APP_NAME_CORE_BILLING_MODULE_INVOICING,
    );

    data.isLoyaltyFeatureEnabled = Feature.getFeatureActiveByAppName(
      Feature.APP_NAME_CORE_SHOP_MODULE_LOYALTY_PROGRAM,
    );

    return data;
  },

  searchOrders() {
    const view = new CustomerOrderListPopup({
      model: this.model,
    });
    view.open()
      .then((resp) => {
        const { orderModel } = resp;
        OrderView.prototype.openReturnProductsPopup.call(this, orderModel);
      });
  },

  changeDiscountClicked() {
    const percentage = this.model.get('permanent_discount') || 0;
    const view = new ChangeDiscountPopup({
      relationModel: this.model,
      percentage,
    });
    view.open().then(() => {
      this.render();
      if (SelectedCustomer.get('id') === this.model.get('id')) {
        SelectedCustomer.set('permanent_discount', this.model.get('permanent_discount'));
        SelectedCustomer.save();
      }
    });
  },
}));
