define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/views/datatables/table/cell',
  'modules/shop.cash-register-retail/templates/datatables/table/headerCell.hbs',
], (
  $, _, Backbone, Cell, Template,
) => Cell.extend({
  template: Template,

  tagName: 'th',

  modelEvents: {
    change: 'render',
  },

  initialize(options) {
    Cell.prototype.initialize.call(this, options);
    this.tableModel = options.tableModel;
  },

  className() {
    let className = '';
    if (this.model.get('className')) {
      className += this.model.get('className');
    }
    if (this.model.has('sortName')) {
      className += ' sortable';
    }
    if (this.options.columnDefinitionModel.has('headerCellClassName')) {
      className += [className, this.options.columnDefinitionModel.get('headerCellClassName')].join(' ');
    }
    return className;
  },

  events: {
    click: 'headerClicked',
  },

  getClassName() {
    if (this.tableModel.has('sortDir')) {
      return `dir_${this.tableModel.get('sortDir')}`;
    }
    return '';
  },

  headerClicked() {
    if (!this.model.has('sortName')) {
      return;
    }

    const modelSort = this.model.get('sortName');

    if (this.tableModel.get('sortName') !== modelSort) {
      this.tableModel.set('sortName', modelSort);
      this.tableModel.set('sortDir', 'asc');
    } else if (this.tableModel.get('sortDir') === 'asc') {
      this.tableModel.set('sortDir', 'desc');
    } else {
      this.tableModel.set('sortDir', 'asc');
    }
  },

  onRender() {
    Cell.prototype.onRender.call(this);
    const className = this.getClassName();

    this.el.classList.remove('dir_');
    this.el.classList.remove('dir_asc');
    this.el.classList.remove('dir_desc');

    if (
      this.tableModel.get('sortName') === this.model.get('sortName')
    ) {
      this.$el.addClass(className);
    }
  },

  onShow() {
    this.tableModel.on('change:sortName', this.render, this);
    this.tableModel.on('change:sortDir', this.render, this);
  },

  onDestroy() {
    this.tableModel.off('change:sortName', this.render, this);
    this.tableModel.off('change:sortDir', this.render, this);
  },

}));
