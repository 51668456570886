define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/datatables/settingsButton.hbs',
], (
  $, _, Backbone, Template,
) => Backbone.Marionette.LayoutView.extend({
  template: Template,

  tagName: 'button',

  className: 'btn btn-default btn-alt btn-block toggle-settings',

  attributes: {
    type: 'button',
  },

  events: {
    click: 'clicked',
  },

  initialize(options) {
    this.tableModel = options.tableModel;
  },

  clicked() {
    this.tableModel.set('settingsShown', !this.tableModel.get('settingsShown'));
  },

  onShow() {
    this.collection.on('all', this.render, this);
  },

  onDestroy() {
    this.collection.off('all', this.render, this);
  },

  onRender() {
    if (this.collection.length > 0) {
      this.$el.show();
    } else {
      this.$el.hide();
    }
  },

}));
