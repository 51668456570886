define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/datatables/presets.hbs',
], (
  $, _, Backbone, Template,
) => Backbone.Marionette.LayoutView.extend({
  template: Template,

  events: {
    'click [data-action]': 'itemClicked',
  },

  itemClicked(ev) {
    const el = ev.currentTarget;
    const id = el.dataset.action;
    const model = this.collection.get(id);
    if (model) {
      const fn = model.get('fn');
      fn();
    }
  },

  serializeData() {
    const width = 100 / this.collection.length;
    return {
      collection: this.collection.map((model) => {
        const data = model.toJSON();
        data.width = width;
        return data;
      }),
    };
  },

}));
