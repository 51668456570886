define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/customers/selection/search',
], (
  $, _, Backbone, Template,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'customer-search full-child-height',

  events: {
    'click button': 'btnClicked',
  },

  btnClicked() {
    this.triggerMethod('layout:swap', 'searching');
  },

}));
