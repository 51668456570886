define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/customerScreens/lockScreen/layout',

  'modules/shop.cash-register-retail/models/customerScreenData',
], (
  $, _, Backbone, Template,
  CustomerScreenDataModel,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'customer-screen-locked',

  serializeData() {
    return {
      companyLogo: CustomerScreenDataModel.getCompanyLogo(),
    };
  },

}));
