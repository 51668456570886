define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/views/datatables/table/bodyCell',
  'modules/common/components/moment',
], (
  $, _, Backbone, View, Moment,
) =>
  /**
     * Shows "Yes" or "No" depending on the path, if  the value is not a "true" or "false" value
     * or the path does not exists it will show an unknown label
     */
  View.extend({

    getContent() {
      let data = null;
      let path = null;
      let translationPath = null;
      if (this.columnDefinitionModel.has('path')) {
        path = this.columnDefinitionModel.get('path');
      }
      if (this.columnDefinitionModel.has('translationPath')) {
        translationPath = this.columnDefinitionModel.get('translationPath');
      }

      /**
             * First checks if translationPath is a string and uses that as model path
             * Secondly checks if translationPath is true, and uses translation.<path> as path for the model
             * Thirdly checks if the path is set in the model
             * Lastly sets data to the detault value;
             */
      if (!_.isNull(translationPath) && _.isString(translationPath) && this.model.has(translationPath)) {
        data = this.model.get(path);
      } else if (!_.isNull(translationPath) && translationPath === true && this.model.has(`translation.${path}`) && this.model.get(`translation.${path}`) !== '') {
        data = this.model.get(`translation.${path}`);
      } else if (!_.isNull(path) && this.model.has(path)) {
        data = this.model.get(path);
      }

      let formatting = 'LLL';
      if (this.columnDefinitionModel.has('dateFormat')) {
        formatting = this.columnDefinitionModel.get('dateFormat');
      }

      if (data) {
        data = new Moment(data).format(formatting);
      } else {
        data = this.columnDefinitionModel.get('default');
      }

      return this.columnDefinitionModel.getBodyCellContent(data, this.model);
    },
  }));
