define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/customers/search.hbs',
  'modules/shop.cash-register-retail/views/customers/list/collection',
  'modules/common/views/swappableWithLoading',

  'modules/shop.cash-register-retail/models/selectedCustomer',
  'modules/shop.cash-register-retail/models/keyboard',

  'upx.modules/RelationsModule/collections/FullInfoRelation',
  'modules/shop.cash-register-retail/collections/upx/DefaultShopConfigurationAttachedGroup',

  'modules/admin/behaviors/loader',

  'modules/shop.cash-register-retail/models/customerScreenData',
], (
  $, _, Backbone, Template,
  CollectionView, Swappable,
  SelectedCustomerModel, KeyboardModel,
  FullInfoRelationCollection, DefaultShopConfigurationAttachedGroupCollection,
  Loader,
  CustomerScreenData,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'customer-search-list',

  ui: {
    search: '[data-ui="search"]',
  },

  events: {
    'submit [data-action="form"]': 'search',
    'click [data-action="add"]': 'addClicked',
    'click [data-action="add-company"]': 'addCompanyClicked',

    'click [data-action="clear"]': 'clearClicked',
    'touchend [data-action="clear"]': 'clearClicked',
  },

  regions: {
    results: '[data-region="results"]',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  addClicked(e) {
    e.preventDefault();
    Backbone.history.navigate('customers/create', { trigger: true });
  },

  addCompanyClicked(e) {
    e.preventDefault();
    Backbone.history.navigate('customers/create-company', { trigger: true });
  },

  onShow() {
    // This ensures that the customer information will be
    // removed when a reload happens while displaying it
    CustomerScreenData.removeCustomerInformation();

    // Set the focus to the search view and popup the keyboard by `clicking` on it
    this.ui.search.focus();
    this.ui.search.click();

    KeyboardModel.on('key:confirm', this.search, this);
  },

  onDestroy() {
    KeyboardModel.off('key:confirm', this.search, this);
  },

  clearClicked() {
    this.ui.search.val('');
    this.ui.search.trigger('keyup');
    this.ui.search.trigger('change');
    // Set the focus to the search view and popup the keyboard by `clicking` on it
    this.ui.search.focus();
    this.ui.search.click();
  },

  search: _.throttle(function () {
    let query = '';
    if (!_.isString(this.ui.search)) {
      query = this.ui.search.val();
    }
    const trimmedQuery = query.trim();
    const isEmpty = trimmedQuery === '';

    const filters = [{
      name: 'group_id__direct_member_in_list',
      val: DefaultShopConfigurationAttachedGroupCollection
        .getIdByAlias(DefaultShopConfigurationAttachedGroupCollection.CUSTOMER_ALIAS),
    }];

    // Check if query is not empty and add the query to the filters.
    if (!isEmpty) {
      filters.push({
        name: 'search__word',
        val: trimmedQuery,
      });
    }

    const dateCreatedSort = {
      name: 'date_created',
      dir: 'desc',
    };

    const def = this.loader.startLoader();
    const fullInfoRelationCollection = new FullInfoRelationCollection();
    const params = {
      params: {
        start: 0,
        limit: 25,
        filters,
        sort: isEmpty ? dateCreatedSort : undefined,
      },
    };

    fullInfoRelationCollection.fetch(params)
      .then(() => {
        def.resolve();
        this.renderResults(fullInfoRelationCollection);
      }, def.reject);
  }, 750),

  renderResults(collection) {
    if (!this.isDestroyed) {
      const region = this.getRegion('results');
      const view = new CollectionView({
        collection,
      });

      (new Swappable()).proxySwappableEvents(view, this);

      view.on('loader:show', () => {
        this.loader.startLoader();
      });

      view.on('loader:hide', () => {
        this.loader.hideLoader();
      });

      region.show(view);
    }
  },

}));
