define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/views/swappableWithLoading',

  './layout',
  './cashup',

  'modules/shop.cash-register-retail/models/settings/pointOfSale',
  'upx.modules/ProductsModule/models/Configuration',
  'upx.modules/ManagementModule/models/Time',
  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',

  'upx.modules/ShopModule/collections/ShopLedger',
  'upx.modules/ProductsModule/collections/TaxRate',

  'modules/common/components/locale',
], (
  $, _, Backbone, SwappableView,
  LayoutView, CashupView,
  PointOfSaleModel, ConfigurationModel, TimeModel, ShopConfiguration,
  ShopLedgerCollection, TaxRateCollection,
  Locale,
) => SwappableView.extend({

  className: 'daily-reports',

  initialize(options) {
    SwappableView.prototype.initialize.call(this, options);
    this.setMainView(LayoutView);
    this.setView('cashup', CashupView);
  },

  loadSharedData(dataStorage) {
    const def = new $.Deferred();
    const shopId = PointOfSaleModel.get('id');

    if (!shopId) {
      return def.reject(Locale.translate('no_point_of_sale_is_set'));
    }

    const configurationModel = new ConfigurationModel();
    const shopLedgerCollection = new ShopLedgerCollection();
    const shopLedgerParameters = {
      start: 0,
      limit: 1,
      filters: [{
        name: 'shop_id__=',
        val: shopId,
      }],
    };

    $.when(
      configurationModel.getDefaultTaxRate(),
      shopLedgerCollection.fetch({ params: shopLedgerParameters }),
      ShopConfiguration.load(true),
    ).then((defaultTaxRate) => {
      const ledger = dataStorage.ledger = shopLedgerCollection.first();

      if (ledger) {
        const timeModel = new TimeModel();
        const taxRateCollection = new TaxRateCollection();
        const taxRateParameters = {
          start: 0,
          limit: 0,
          sort: [{
            name: 'value',
            dir: 'asc',
          }],
          filters: [{
            name: 'country_iso2',
            val: defaultTaxRate.country_iso2,
          }],
        };

        $.when(
          timeModel.fetch(),
          taxRateCollection.fetch({ params: taxRateParameters }),
        ).then((serverTime) => {
          dataStorage.serverTime = serverTime;
          dataStorage.taxRates = taxRateCollection.map((taxRate) => taxRate.get('value') * 100);
          def.resolve();
        }, def.reject);
      } else {
        def.reject(Locale.translate('no_point_of_sale_is_set'));
      }
    }, def.reject);

    return def;
  },

}));
