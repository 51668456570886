define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/customerScreens/orderScreen/orderInformation/layout',

  'modules/shop.cash-register-retail/models/customerScreenData',

  './collection',
  './totals',
], (
  $, _, Backbone, Template,
  CustomerScreenDataModel,
  CollectionView, Totals,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'customer-screen-order-information',

  serializeData() {
    return {
      companyLogo: CustomerScreenDataModel.getCompanyLogo(),
    };
  },

  regions: {
    products: '[data-region=products]',
    totals: '[data-region=totals]',
  },

  onRender() {
    this.renderProducts();
    this.renderTotals();
  },

  renderProducts() {
    const region = this.getRegion('products');
    const view = new CollectionView();
    region.show(view);
  },

  renderTotals() {
    const region = this.getRegion('totals');
    const view = new Totals();
    region.show(view);
  },

}));
