define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/datatables/search.hbs',
], (
  $, _, Backbone, Template,
) => Backbone.Marionette.LayoutView.extend({
  template: Template,

  className: 'input-group search',

  ui: {
    searchField: '[data-action="search"]',
  },

  events: {
    'click [data-action="clear"]': 'clearSearch',
    'keyup @ui.searchField': 'updateSearch',
  },

  initialize(options) {
    this.tableModel = options.tableModel;
  },

  onShow() {
    this.updateFromModel();
    this.printHideSearch();
    this.tableModel.on('change:search', this.updateFromModel, this);
  },

  onDestroy() {
    this.tableModel.off('change:search', this.updateFromModel, this);
  },

  updateFromModel() {
    this.setSearch(this.tableModel.get('search'));
  },

  setSearch(searchTerm) {
    searchTerm = searchTerm || '';
    this.ui.searchField.val(searchTerm);
  },

  updateSearch() {
    this.tableModel.set('search', this.getSearch());
    this.printHideSearch();
  },

  printHideSearch() {
    // Show the search if it has text
    if (this.tableModel.get('search')) {
      this.$el.removeClass('no-print');
    } else {
      // Else we are hiding the input
      this.$el.addClass('no-print');
    }
  },

  clearSearch() {
    this.tableModel.set('search', '');
  },

  getSearch() {
    return this.ui.searchField.val();
  },

}));
