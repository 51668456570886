define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/behaviors/cellWithKeyboard',
  'modules/common/components/currency',
], (
  $, _, Backbone, WithKeyboardBehaviour, Currency,
) => Backbone.Marionette.ItemView.extend({
  behaviors: {
    WithKeyboardBehaviour: {
      behaviorClass: WithKeyboardBehaviour,
    },
  },

  ui: {
    input: 'input',
  },

  currentValue: null,

  firstInput: true,

  template() {
    return '<input style="background-color: #FFFFFF;" type="text" readonly="readonly" ignore-keyboard="true" class="form-control text-right" placeholder="€ 0,00">';
  },

  initialize() {
    this.keyboardMode = this.keyboardModel.MODE_CASH;

    this.model.set('value', this.model.get('value') || '0.00');
    this.currentValue = this.model.get('value');

    const self = this;

    this.model.on('change:value', (model, value) => {
      self.currentValue = value;
      if (!self.isDestroyed) {
        self.render();
      }
    });
  },

  onKeyPress(key) {
    if (this.keyboardModel.isNumberComaKey(key)) {
      let currentPrice = this.currentValue;

      if (this.firstInput) {
        currentPrice = '0.00';
        this.firstInput = false;
      }

      let newPrice = this.handleInputOnPrice(currentPrice, key);

      if (parseFloat(newPrice) > 100000.00) {
        newPrice = '100000.00';
      }

      this.currentValue = newPrice;
    }
  },

  onBackspacePress() {
    // Mark input as changed
    this.firstInput = false;
    // Update the value
    this.currentValue = this.removeDigitFromPrice(this.currentValue);
  },

  onRender() {
    this.$el.css({
      display: 'contents',
    });

    this.ui.input.val(Currency.format('EUR', this.currentValue));
  },

  onConfirmation() {
    this.model.set('value', this.currentValue);
  },

  onCancel() {
    this.currentValue = this.model.get('value');
    if (!this.isDestroyed) {
      this.render();
    }
  },
}));
