define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/datatables/position',
], (
  $, _, Backbone, Template,
) => Backbone.Marionette.LayoutView.extend({
  template: Template,

  className: 'position-container',

  initialize(options) {
    this.tableModel = options.tableModel;
  },

  onShow() {
    this.tableModel.on('change', this.render, this);
  },

  onDestroy() {
    this.tableModel.off('change', this.render, this);
  },

  serializeData() {
    const currentPage = this.tableModel.get('page');
    const limit = this.tableModel.get('limit');
    const collectionTotal = this.tableModel.get('collectionTotal');

    let lastItem = (limit * currentPage) + limit;
    if (lastItem > collectionTotal) {
      lastItem = collectionTotal;
    }

    let firstItem = (limit * currentPage) + 1;
    if (collectionTotal <= 0) {
      firstItem = 0;
    }

    return {
      firstItem,
      lastItem,
      totalItems: collectionTotal,
    };
  },

}));
