define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/customers/loyalty.hbs',

  'modules/shop.cash-register-retail/views/popups/addLoyaltyOperationPopup.js',

  'modules/common/components/historyBreadcrumb',
], (
  $, _, Backbone, Template,
  AddLoyaltyOperationPopup,
  HistoryBreadcrumb,
) => Backbone.Marionette.LayoutView.extend({
  template: Template,

  className: 'customer-view',

  regions: {
    popup: '[data-region="popup"]',
  },

  events: {
    'click [data-action="back"]': 'backClicked',
    'click [data-action="add"]': 'addClicked',
    'click [data-action="remove"]': 'removeClicked',
  },
  initialize(options) {
    this.loyaltyCustomerOperationCollection = options.loyaltyCustomerOperationCollection;
  },
  backClicked() {
    HistoryBreadcrumb.goBack({ skipSameRoute: false });
  },

  openOperationPopup(addPoints) {
    const safeDef = new $.Deferred();
    const popop = new AddLoyaltyOperationPopup({
      customerModel: this.model,
      addPoints,
      safeDef,
    });
    popop.open();
    safeDef.then(() => {
      this.model.set('loyalty_customer', null);
      this.triggerMethod('swappable:reloadViewStorage', 'loyalty');
      this.triggerMethod('layout:swap', 'loyalty');
    });
  },

  addClicked: _.debounce(function () {
    this.openOperationPopup(true);
  }, 30),
  removeClicked: _.debounce(function () {
    this.openOperationPopup(false);
  }, 30),
  serializeData() {
    const data = this.model.toJSON();
    data.loyaltyCustomerOperations = this.loyaltyCustomerOperationCollection.toJSON();
    return data;
  },
}));
