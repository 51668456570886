define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/calendar/layout.hbs',

  './selector/layout',
  './calendar/layout',
], (
  $, _, Backbone, Template,
  SelectorView, CalendarView,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'calendar-overview',

  initialize() {
    this.model = new Backbone.Model({
      relation_data_id: null,
    });
  },

  regions: {
    selector: '[data-region=selector]',
    calendar: '[data-region=calendar]',
  },

  onRender() {
    this.renderSelector();
    this.renderCalendar();
  },

  renderSelector() {
    const region = this.getRegion('selector');
    const view = new SelectorView({
      model: this.model,
    });
    region.show(view);
  },

  renderCalendar() {
    const region = this.getRegion('calendar');
    const view = new CalendarView({
      model: this.model,
    });
    region.show(view);
  },

}));
