define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/calendar/selector/layout.hbs',

  'modules/shop.cash-register-retail/collections/employee',
], (
  $, _, Backbone, Template, EmployeeCollection,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'calendar-overview__selector',

  serializeData() {
    return {
      employee: EmployeeCollection.toJSON(),
    };
  },

  events: {
    'change [data-action=employee]': 'employeeChanged',
  },

  employeeChanged(ev) {
    const el = ev.currentTarget;
    const { value } = el;
    if (value === 'all') {
      this.model.set('relation_data_id', null);
    } else {
      this.model.set('relation_data_id', value);
    }
  },

}));
