define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/datatables/pagination',
], (
  $, _, Backbone, Template,
) => Backbone.Marionette.LayoutView.extend({
  template: Template,

  tagName: 'ul',

  className: 'collection-pagination',

  ui: {
    firstBtn: '[data-action="first"]',
    previousBtn: '[data-action="previous"]',
    positionBtn: '[data-action="position"]',
    nextBtn: '[data-action="next"]',
  },

  events: {
    'click @ui.firstBtn': 'firstClicked',
    'click @ui.previousBtn': 'previousClicked',
    'click @ui.positionBtn': 'positionClicked',
    'click @ui.nextBtn': 'nextClicked',
  },

  initialize(options) {
    this.tableModel = options.tableModel;
  },

  onShow() {
    this.tableModel.on('change', this.render, this);
  },

  onDestroy() {
    this.tableModel.off('change', this.render, this);
  },

  firstClicked() {
    this.tableModel.set('page', 0);
  },

  previousClicked() {
    let page = this.tableModel.get('page');
    page--;
    if (page < 0) {
      page = 0;
    }
    this.tableModel.set('page', page);
  },

  positionClicked(e) {
    const $el = $(e.currentTarget);
    const newPageString = $el.data('position');
    const newPage = parseInt(newPageString);

    if (newPage >= 0) {
      this.tableModel.set('page', newPage);
    } else {
      this.tableModel.set('page', 0);
    }
  },

  nextClicked() {
    let page = this.tableModel.get('page');
    page++;
    if (page < 0) {
      page = 0;
    }
    this.tableModel.set('page', page);
  },

  generatePages() {
    let steps = 3;
    if (window.innerWidth < 991) {
      steps = 2;
    }
    const currentPage = this.tableModel.get('page');
    const limit = this.tableModel.get('limit');
    const collectionTotal = this.tableModel.get('collectionTotal');
    const currentPosition = limit * currentPage;
    const maxPosition = Math.ceil(collectionTotal / limit) - 1;

    let beforeSteps = 0;
    let afterSteps = 0;

    /* Calculate home many steps are in front of, and after this current page are */
    for (let b = 1; b <= steps; b++) {
      const previousPosition = currentPosition - (b * limit);
      if (previousPosition >= 0) {
        beforeSteps++;
      } else {
        afterSteps++;
      }
    }

    for (let a = 1; a <= steps; a++) {
      const nextPosition = currentPosition + (a * limit);
      if (nextPosition <= collectionTotal) {
        afterSteps++;
      } else {
        beforeSteps++;
      }
    }

    // Now for looping over the before step
    const pageArray = [];
    for (let bp = beforeSteps; bp >= 1; bp--) {
      const page = currentPage - bp;
      if (page >= 0) {
        pageArray.push({
          label: page + 1,
          position: page,
          is_current: false,
        });
      }
    }

    // Add the current page
    pageArray.push({
      label: currentPage + 1,
      position: currentPage,
      is_current: true,
    });

    // Now for looping over the after steps
    for (let ap = 1; ap <= afterSteps; ap++) {
      const page = currentPage + ap;
      if (page <= maxPosition) {
        pageArray.push({
          label: (page + 1).toString(),
          position: page,
          is_current: false,
        });
      }
    }

    return pageArray;
  },

  getPagination() {
    // Default Variables
    const total = this.tableModel.get('collectionTotal');
    const currentPos = this.tableModel.get('page');
    const currentVisible = this.tableModel.get('limit');
    const data = {
      hasPrevious: false,
      pages: this.generatePages(),
      hasNext: false,
      isFirst: true,
    };

    if (currentPos >= 1) {
      data.isFirst = false;
      data.hasPrevious = true;
    }

    if (((currentPos + 1) * currentVisible) < total) {
      data.hasNext = true;
    }

    if (!data.hasPrevious && !data.hasNext) {
      data.pages = [{
        label: '1',
        position: 1,
        is_current: true,
      }];
    }

    return data;
  },

  serializeData() {
    return this.getPagination();
  },

}));
