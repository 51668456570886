define([
  'jquery',
  'underscore',
  'backbone',

  'modules/shop.cash-register-retail/views/datatables/table/bodyCellEmpty',
  'modules/common/views/swappableWithLoading',
], (
  $, _, Backbone,
  EmptyView, Swappable,
) => Backbone.Marionette.CollectionView.extend({
  tagName: 'tr',

  initialize(options) {
    this.tableModel = options.tableModel;
    this.tableView = options.tableView;
  },

  getChildView(model) {
    return model.getBodyCellCls();
  },

  onAddChild(view) {
    // forward Swappable events up
    (new Swappable()).proxySwappableEvents(view, this);
  },

  childViewOptions(model) {
    return model.getBodyCellOptions({
      model: this.model,
      tableModel: this.tableModel,
      tableView: this.tableView,
    });
  },

  emptyView: EmptyView,
}));
