define([
  'jquery',
  'underscore',
  'backbone',

  './lockScreen/layout',
  './orderScreen/layout',
  './customerInformation/layout',

  'modules/shop.cash-register-retail/collections/currentOrderItem',
  'modules/shop.cash-register-retail/collections/currentPaymentMethodItem',
  'modules/shop.cash-register-retail/models/selectedCustomer',
  'modules/shop.cash-register-retail/models/customerScreenData',
  'modules/upx/collections/users',
], (
  $, _, Backbone,
  LockScreen, OrderScreen, CustomerInformation,
  OrderItemCollection, PaymentMethodItemCollection, SelectedCustomerModel, CustomerScreenDataModel, UserCollection,
) => Backbone.Marionette.LayoutView.extend({

  template() {
    return '<div data-region="main"></div>';
  },

  initialize(options) {
    this.shopId = options.shopId;
    this.forceScreen = options.forceScreen;
  },

  regions: {
    main: '[data-region=main]',
  },

  onRender() {
    this.updateCurrentView();
  },

  updateCurrentView() {
    const region = this.getRegion('main');
    const view = this.getCurrentView();
    region.show(view);
  },

  getCurrentView() {
    if (this.forceScreen) {
      if (this.forceScreen === 'lock') {
        return new LockScreen();
      } if (this.forceScreen === 'customer') {
        return new CustomerInformation();
      }
      return new OrderScreen({
        shopId: this.shopId,
      });
    }
    if (CustomerScreenDataModel.get('onLoginScreen')) {
      return new LockScreen();
    }
    if (CustomerScreenDataModel.get('displayCustomerInformation')) {
      return new CustomerInformation();
    }
    return new OrderScreen();
  },

  onShow() {
    // View based events
    CustomerScreenDataModel.on('change:onLoginScreen', this.updateCurrentView, this);
    CustomerScreenDataModel.on('change:companyLogo', this.updateCurrentView, this);
    CustomerScreenDataModel.on('change:displayCustomerInformation', this.updateCurrentView, this);

    // Reload events
    CustomerScreenDataModel.on('change:companyLogo', this.reload, this);
    CustomerScreenDataModel.on('change:reloadCustomerScreen', this.conditionalReload, this);

    // marks that the customer screen just reloaded.
    CustomerScreenDataModel.customerScreenReloaded();

    // Initialize loop
    this.updateLoop = setInterval(() => this.updateLocalStorageData(), 500);
  },

  onDestroy() {
    // View based events
    CustomerScreenDataModel.off('change:onLoginScreen', this.updateCurrentView, this);
    CustomerScreenDataModel.off('change:companyLogo', this.updateCurrentView, this);
    CustomerScreenDataModel.off('change:displayCustomerInformation', this.updateCurrentView, this);

    // Reload events
    CustomerScreenDataModel.off('change:companyLogo', this.reload, this);
    CustomerScreenDataModel.off('change:reloadCustomerScreen', this.conditionalReload, this);

    // Clear the updateLoop
    clearInterval(this.updateLoop);
  },

  reload() {
    // Force reload
    location.reload();
  },

  conditionalReload() {
    if (CustomerScreenDataModel.needsToReloadCustomerScreen()) {
      CustomerScreenDataModel.customerScreenReloaded();
      this.reload();
    }
  },

  /**
		 * This function should fetch all local storage data so other views dont need to.
		 */
  updateLocalStorageData() {
    OrderItemCollection.fetch();
    PaymentMethodItemCollection.fetch();
    SelectedCustomerModel.fetch();
    CustomerScreenDataModel.fetch();
    UserCollection.fetch();
  },

}));
