define([
  'jquery',
  'underscore',
  'backbone',

  'modules/shop.cash-register-retail/views/datatables/table/bodyRow',
  'modules/shop.cash-register-retail/views/datatables/table/bodyRowEmpty',
  'modules/common/views/swappableWithLoading',
], (
  $, _, Backbone,
  ItemView, EmptyView, Swappable,
) => Backbone.Marionette.CollectionView.extend({

  tagName: 'tbody',

  childView: ItemView,

  initialize(options) {
    this.columnDefinitionCollection = options.columnDefinitionCollection;
    this.tableView = options.tableView;
    this.tableModel = options.tableModel;
    this.settingCollection = options.settingCollection;
  },

  childViewOptions() {
    return {
      collection: this.columnDefinitionCollection,
      tableView: this.tableView,
      tableModel: this.tableModel,
    };
  },

  emptyView: EmptyView,

  onAddChild(view) {
    // forward Swappable events up
    (new Swappable()).proxySwappableEvents(view, this);
  },

  emptyViewOptions() {
    return {
      collection: this.columnDefinitionCollection,
      tableModel: this.tableModel,
      settingCollection: this.settingCollection,
    };
  },

}));
