define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/components/printing',
  'modules/shop.cash-register-retail/templates/dailyReports/cashup.hbs',
  'modules/common/components/moment',
  'modules/shop.cash-register-retail/models/keyboard',
  'modules/shop.cash-register-retail/views/keypads/main',
  'modules/shop.cash-register-retail/views/popups/cashAmountPopup',
  'modules/shop.cash-register-retail/views/dailyReports/currencyInput',
  'modules/shop.cash-register-retail/views/popups/confirmPopup',
  'modules/common/components/locale',
  'modules/admin/behaviors/loader',
  'modules/upx/components/upx',
  'modules/common/components/currency',
  'modules/shop.cash-register-retail/views/popups/messagePopup',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.common/components/mode',
], (
  $, _, Backbone, PrintingComponent, Template, Moment, KeyboardModel, KeypadView, CashAmountPopup, CurrencyInputView, ConfirmPopup, Locale, Loader, Upx, Currency, MessagePopup, CashRegisterApi, Mode,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'daily-cashup',

  ui: {
    back: '#back',
    finalize: '#finalize',
    cashInRegisterHelper: '[data-action="cash-in-register-helper"]',
    cashSkimmedHelper: '[data-action="cash-skimmed-helper"]',
    note: '#note',
    differenceCash: '#difference-cash',
    differenceOther: '#difference-other',
    print: '#print',
    ticket: '[data-ui=ticket]',
  },

  regions: {
    keypad: '[data-region=keypad]',
    cashInRegister: '[data-region="cash-in-register"]',
    cashSkimmed: '[data-region="cash-skimmed"]',
  },

  events: {
    'click @ui.back': 'backClicked',
    'click @ui.finalize': 'finalizeClicked',
    'click @ui.cashInRegisterHelper': 'cashInRegisterHelperClicked',
    'click @ui.cashSkimmedHelper': 'cashSkimmedHelperClicked',
    'click @ui.print': 'printClicked',

    'focus @ui.note': 'noteFocused',
    'click @ui.note': 'noteFocused',
  },

  noteFocused() {
    KeyboardModel.resetMode();
  },

  cashDifferenceOk: false,

  otherDifferenceOK: true,

  initialize(options) {
    this.currency_iso3 = options.ledger.get('currency_iso3');

    const self = this;
    let expectedOther = '0.00';
    const sums = this.options.snapshot.get('shop_ledger_snapshot.shop_ledger_snapshot_sums');

    $.each(sums, (key, sum) => {
      if (sum.type === 'PaymentModule::Other' && sum.type_alias === 'PaymentByType') {
        expectedOther = sum.balance_change;
      }
    });

    this.expectedOther = expectedOther;
    this.expectedCash = this.options.snapshot.get('balance_after');

    this.cashInRegisterModel = new Backbone.Model({
      value: '0.00',
    });
    this.cashSkimmedModel = new Backbone.Model({
      value: '0.00',
    });

    this.cashInRegisterModel.on('change:value', () => {
      self.checkAmountChanged(self.cashInRegisterModel);
      self.checkCashDifference();
    });

    this.cashSkimmedModel.on('change:value', () => {
      self.checkAmountChanged(self.cashSkimmedModel);
      self.checkCashDifference();
    });

    this.behaviors = {
      Loader: {
        behaviorClass: Loader,
      },
    };

    this._behaviors = Marionette.Behaviors(this);
  },

  getDifference() {
    const cashInRegister = Currency.currencyToFloat(this.cashInRegisterModel.get('value'));
    const cashSkimmed = Currency.currencyToFloat(this.cashSkimmedModel.get('value'));

    return -(this.expectedCash - cashInRegister - cashSkimmed);
  },

  checkCashDifference() {
    const difference = this.getDifference();

    this.ui.differenceCash.html(
      Currency.format(this.currency_iso3, Currency.toCurrency(difference)),
    );

    if (difference > 0) {
      this.ui.differenceCash.removeClass('text-danger').addClass('text-success');
      this.cashDifferenceOk = false;
    } else if (difference < 0) {
      this.ui.differenceCash.removeClass('text-success').addClass('text-danger');
      this.cashDifferenceOk = false;
    } else {
      this.ui.differenceCash.removeClass('text-success').removeClass('text-danger');
      this.cashDifferenceOk = true;
    }
  },

  backClicked() {
    this.triggerMethod('layout:swap');
  },

  printClicked() {
    const def = this.loader.startLoader();

    const data = {
      currentDate: Moment().format('YYYY-MM-DD HH:mm:ss'),
      note: this.ui.note.val(),
      currency_iso3: this.currency_iso3,
      expectedCash: this.expectedCash,
      cashInRegister: this.cashInRegisterModel.get('value'),
      cashSkimmed: this.cashSkimmedModel.get('value'),
      difference: this.getDifference().toFixed(2),
    };

    PrintingComponent.printCashup(data).then(def.resolve, def.reject);
  },

  finalizeClicked() {
    CashRegisterApi.logAction('CASHUP_SAVING_INITIALIZE', {
      cashDifferenceOk: this.cashDifferenceOk,
      otherDifferenceOK: this.otherDifferenceOK,
    });
    if (!this.cashDifferenceOk || !this.otherDifferenceOK) {
      const note = this.ui.note.val();

      if (note.length < 1) {
        const messageView = new MessagePopup();
        messageView.open(
          Locale.translate('there_is_a_cash_difference_please_fill_in_the_remarks_dot'),
        );

        CashRegisterApi.logAction('CASHUP_SAVING_ERROR', {
          cashDifferenceOk: this.cashDifferenceOk,
          otherDifferenceOK: this.otherDifferenceOK,
          error: Locale.translate('there_is_a_cash_difference_please_fill_in_the_remarks_dot'),
        });

        return;
      }
    }

    const self = this;
    const def = new $.Deferred();
    const view = new ConfirmPopup();

    view.open(
      Locale.translate('finalize'),
      Locale.translate('are_you_sure_you_want_to_finalize_the_cash_up_question'),
      def,
    );

    def.done(() => {
      // we just send the expected other to mitigate the error, than
      // when you mark as paid the invoice in backoffice, which was made from POS it will show up here as other
      // see: https://app.clickup.com/t/78qfvm
      // sometimes if ends up negative so we set to 0, see https://app.clickup.com/t/n3eckp
      const otherCounted = self.expectedOther > 0 ? self.expectedOther : 0;
      const params = {
        fields: {
          cash_counted_after_skim: self.cashInRegisterModel.get('value'),
          cash_skim_amount: self.cashSkimmedModel.get('value'),
          other_counted: otherCounted,
          shop_ledger_snapshot: {
            shop_ledger_id: self.options.ledger.get('id'),
            note: self.ui.note.val(),
          },

        },
      };

      CashRegisterApi.logAction('CASHUP_SAVING_START', {
        parameters: params,
      });

      const def = self.loader.startLoader();

      Upx.call('ShopModule', 'newCashDrawerSnapshot', params).then(
        (id) => {
          def.resolve();
          CashRegisterApi.logAction('CASHUP_SAVING_SUCCESS', {
            id,
            parameters: params,
          });
          self.triggerMethod('layout:swap');
        },
        (res) => {
          def.reject();
          const messageView = new MessagePopup();
          messageView.open(res.error);
          CashRegisterApi.logAction('CASHUP_SAVING_ERROR', {
            error: res,
            parameters: params,
          });
        },
      );
    });
  },

  checkAmountChanged(model) {
    const currentValue = model.get('value');
    const previousCalculatedAmount = model.get('previous-calculated-amount');

    // reset previous calculated amounts
    if (previousCalculatedAmount !== null && currentValue !== previousCalculatedAmount) {
      model.set('amounts', []);
    }
  },

  cashInRegisterHelperClicked() {
    this.cashHelperClicked(this.cashInRegisterModel);
  },

  cashSkimmedHelperClicked() {
    this.cashHelperClicked(this.cashSkimmedModel);
  },

  cashHelperClicked(model) {
    const popup = new CashAmountPopup();
    const currentAmounts = model.get('amounts') || [];
    const calculator = popup.open(currentAmounts);

    $.when(calculator.promise()).then(
      (response) => {
        model.set('amounts', response.currentAmounts);
        model.set('previous-calculated-amount', response.total);
        model.set('value', response.total);
      },
    );
  },

  onRender() {
    const self = this;

    const keypadRegion = this.getRegion('keypad');
    keypadRegion.show(new KeypadView());

    const cashInRegisterRegion = this.getRegion('cashInRegister');
    cashInRegisterRegion.show(new CurrencyInputView({
      model: this.cashInRegisterModel,
    }));

    const cashSkimmedRegion = this.getRegion('cashSkimmed');
    cashSkimmedRegion.show(new CurrencyInputView({
      model: this.cashSkimmedModel,
    }));

    this.checkCashDifference();
  },

  serializeData() {
    let expectedOther = '0.00';
    const sums = this.options.snapshot.get('shop_ledger_snapshot.shop_ledger_snapshot_sums');

    $.each(sums, (key, sum) => {
      if (sum.type === 'PaymentModule::Other' && sum.type_alias === 'PaymentByType') {
        expectedOther = sum.balance_change;
      }
    });

    return {
      isInWebOrAppMode: Mode.isInWebMode() || Mode.isInAppMode(),
      expectedCash: this.expectedCash,
      dayShowing: Moment(new Date()).format('LL'),
      iso3: this.options.ledger.get('currency_iso3'),
    };
  },
}));
