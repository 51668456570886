define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/datatables/table/layout.hbs',

  'modules/common/collections/DeepModelCollection',

  'modules/shop.cash-register-retail/views/datatables/table/headerRow',
  'modules/shop.cash-register-retail/views/datatables/table/footerRow',
  'modules/shop.cash-register-retail/views/datatables/table/body',
  'modules/common/views/swappableWithLoading',
], (
  $, _, Backbone, Template,
  DeepModelCollection,
  HeaderView, FooterView, BodyView, Swappable,
) => Backbone.Marionette.LayoutView.extend({
  template: Template,

  className: 'upx-datatable__table-container',

  ui: {
    header: 'thead',
    body: 'tbody',
    footer: 'tfoot',
  },

  regions: {
    header: 'thead',
    body: {
      selector: 'table',
      regionClass: Backbone.Marionette.Region.extend({
        attachHtml(view) {
          this.$el.append(view.el);
        },
      }),
    },
    footer: 'tfoot',
  },

  initialize(options) {
    this.columnDefinitionCollection = options.columnDefinitionCollection;
    this.tableModel = options.tableModel;
    this.tableView = options.tableView;
    this.settingCollection = options.settingCollection;
  },

  onRender() {
    this.renderHeader();
    this.renderBody();
    if (this.shouldRenderFooter()) {
      this.renderFooter();
    }
  },

  renderHeader() {
    const region = this.getRegion('header');
    const view = new HeaderView({
      collection: this.columnDefinitionCollection,
      tableModel: this.tableModel,
      tableView: this.tableView,
      tableCollection: this.collection,
    });

    (new Swappable()).proxySwappableEvents(view, this);

    region.show(view);
  },

  renderBody() {
    const region = this.getRegion('body');
    const view = new BodyView({
      collection: this.collection,
      columnDefinitionCollection: this.columnDefinitionCollection,
      tableModel: this.tableModel,
      tableView: this.tableView,
      settingCollection: this.settingCollection,
    });

    (new Swappable()).proxySwappableEvents(view, this);

    region.show(view);
  },

  shouldRenderFooter() {
    let render = false;
    this.columnDefinitionCollection.each((model) => {
      render = render || (!!model.get('footerCellCls'));
    });
    return render;
  },

  renderFooter() {
    const region = this.getRegion('footer');
    const view = new FooterView({
      collection: this.columnDefinitionCollection,
      tableModel: this.tableModel,
      tableView: this.tableView,
      tableCollection: this.collection,
    });

    (new Swappable()).proxySwappableEvents(view, this);

    region.show(view);
  },

}));
