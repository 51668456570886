define([
  'jquery',
  'underscore',
  'backbone',
], (
  $, _, Backbone,
) => Backbone.Marionette.CollectionView.extend({

  className: 'upx-datatable__labels-container',

  onShow() {
    this.collection.on('all', this.render, this);
  },

  onDestroy() {
    this.collection.on('all', this.render, this);
  },

  getChildView(model) {
    return model.getLabelViewCls();
  },

  childViewOptions(model) {
    return model.getLabelViewOptions();
  },

}));
