define([
  'jquery',
  'underscore',
  'backbone',

  'modules/shop.cash-register-retail/views/datatables/table/headerCellEmpty',
], (
  $, _, Backbone,
  EmptyView,
) => Backbone.Marionette.CollectionView.extend({

  initialize(options) {
    this.tableModel = options.tableModel;
    this.tableView = options.tableView;
    this.tableCollection = options.tableCollection;
  },

  getChildView(model) {
    return model.getHeaderCellCls();
  },

  childViewOptions(model) {
    return model.getHeaderCellOptions({
      tableModel: this.tableModel,
      tableView: this.tableView,
      tableCollection: this.tableCollection,
    });
  },

  emptyView: EmptyView,

  tagName: 'tr',

}));
