define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/customers/changeDiscount.hbs',

  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/toaster',
  'modules/admin/templates/alerts/danger',
  'modules/upx/behaviors/memento',

  'modules/upx/components/upx',
  'upx.modules/ShopModule/models/CustomerPermanentDiscount',

  'modules/admin/behaviors/loader',
  'modules/upx/behaviors/validation',
  'modules/upx/behaviors/modelBinder',
  'modules/mobile/views/popup',
  'modules/shop.cash-register-retail/components/cashRegisterApi',

], (
  $, _, Backbone, Template,
  Locale, Toaster, DangerAlert, Memento,
  Upx, CustomerPermanentDiscountModel,
  Loader, Validation, ModelBinder, PopupView, CashRegisterApi,
) => PopupView.extend({

  className: 'dialog dialog--confirm',
  template: Template,

  events: {

    'submit @ui.form': 'onSave',

    'click @ui.close': 'closeClicked',
    'touchend @ui.close': 'closeClicked',

    'click @ui.confirm': 'onSave',
    'touchend @ui.confirm': 'onSave',
  },
  ui: {
    confirm: '[data-action="confirm"]',
    close: '[data-action="close"]',
    percentageInput: '#percentage',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
    Memento: {
      behaviorClass: Memento,
    },
    ModelBinder: {
      behaviorClass: ModelBinder,
      method: 'setCustomerPermanentDiscount',
    },
    Validation: {
      behaviorClass: Validation,
      method: 'setCustomerPermanentDiscount',
      rules: {
        percentage: {
          required: true,
          min: 0,
          max: 100,
        },
      },
    },
  },

  initialize(options) {
    this.relationModel = options.relationModel;
    this.model = new CustomerPermanentDiscountModel({
      relation_data_id: options.relationModel.get('id'),
      percentage: options.percentage,
    });
  },

  open(def) {
    def = this.renderInFloatingRegion(def);

    CashRegisterApi.logAction('POPUP_OPEN', {
      type: 'modules/shop.cash-register-retail/views/customers/changeDiscount',
    });

    this.openPopup();

    return def;
  },

  onSave() {
    this.model.set('percentage', this.ui.percentageInput.val()); // model binder does not work, don't know why
    if (this.model.isValid(true)) {
      const def = this.loader.startLoader();

      this.model.setCustomerPermanentDiscount()
        .then(() => {
          this.relationModel.set('permanent_discount', this.model.get('percentage'));
          def.resolve();
          this.confirm();
        },
        // fails
        (response) => {
          def.reject();

          this.$el.find('#message').html(
            DangerAlert({
              id: 'error',
              dismissable: true,
              title: Locale.translate('error'),
              description: response.error,
            }),
          );
        });
    }
  },

}));
