define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/customers/selection/selected',

  'modules/shop.cash-register-retail/models/selectedCustomer',

  'modules/common/components/locale',
  'modules/common/components/moment',

  'modules/shop.cash-register-retail/views/popups/confirmPopup',
  'modules/shop.cash-register-retail/views/popups/messagePopup',
  'modules/common/components/currency',
], (
  $, _, Backbone, Template,
  SelectedCustomerModel,
  Locale, Moment,
  ConfirmPopup,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'customer-selection__selected full-child-height',

  events: {
    'click [data-edit]': 'editClicked',
    'click [data-change]': 'changeClicked',
  },

  initialize({ model = null, allowChange = true }) {
    this.model = model || SelectedCustomerModel;
    this.allowChange = allowChange;
  },

  editClicked() {
    if (this.allowChange) {
      Backbone.history.navigate(`customers/details/${this.model.get('id')}`, { trigger: true });
    }
  },

  changeClicked() {
    if (this.allowChange) {
      const def = new $.Deferred();

      def.done(() => {
        this.model.unload();
        this.triggerMethod('layout:swap');
      });

      (new ConfirmPopup()).open(
        Locale.translate('are_you_sure_you_want_to_deselect_this_customer_question'),
        null,
        def,
      );
    }
  },

  regions: {
    popup: '[data-regions=popup]',
  },

  getSalutation() {
    if (this.model.has('contact_person.ismale')) {
      return this.model.get('contact_person.ismale') ? Locale.translate('mr') : Locale.translate('ms');
    }
  },

  getAge() {
    const today = new Moment();
    const birth = new Moment(this.model.get('contact_person.birthdate'));
    return today.diff(birth, 'years');
  },

  serializeData() {
    return {
      salutation: this.getSalutation(),
      name: this.model.get('name'),
      street: this.model.get('contact_address.street'),
      street_number: this.model.get('contact_address.streetnumber'),
      postal_code: this.model.get('contact_address.zipcode'),
      country: this.model.get('contact_address.country_iso2'),
      city: this.model.get('contact_address.city'),
      age: this.getAge(),
      birthdate: this.model.get('contact_person.birthdate'),
      permanent_discount: this.model.get('permanent_discount'),
      membercard_note: this.model.get('notes_membercard'),
      membercard_duedate: this.model.get('due_date_membercard'),
      membercard_code: this.model.get('code_membercard'),
      allowChange: this.allowChange,
    };
  },

}));
