define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/customers/outstandingInvoices/collectionItem',

  'modules/shop.cash-register-retail/collections/routeCache',
], (
  $, _, Backbone, Template,
  RouteCache,
) => Backbone.Marionette.LayoutView.extend({

  tagName: 'tr',

  template: Template,

  events: {
    'click [data-action=pay]': 'payClicked',
  },

  payClicked() {
    const cacheId = RouteCache.createCache({
      invoiceId: this.model.get('id'),
    });
    Backbone.history.navigate(`payment-screen/invoice/${cacheId}`, { trigger: true });
  },

}));
