define([
  'backbone',
  'modules/shop.cash-register-retail/templates/checkoutButtons/cardButtons.hbs',

  'modules/shop.cash-register-retail/components/checkoutCards',
], (
  Backbone, Template,
  CheckoutCardsComponent,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'card-buttons',

  regions: {
    popup: '[data-region="popup"]',
  },

  events: {
    'click [data-action="top-up"]': 'topUpClicked',
    'click [data-action="paying-card"]': 'payingCardClicked',
    'click [data-action="member-card"]': 'memberCardClicked',
  },

  initialize() {
    CheckoutCardsComponent.loadProducts();
  },

  topUpClicked: _.debounce(() => {
    CheckoutCardsComponent.topUpPopup();
  }, 50),

  payingCardClicked: _.debounce(() => {
    CheckoutCardsComponent.giftCardPopup();
  }, 50),

  memberCardClicked: _.debounce(() => {
    CheckoutCardsComponent.memberCardWithBalancePopup();
  }, 50),
}));
