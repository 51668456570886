define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/customerScreens/orderScreen/layout',

  './orderInformation/layout',
  './imageSlider/swappable',
  'modules/shop.cash-register-retail/views/customerScreens/orderScreen/qrPayment.js',

  'modules/shop.cash-register-retail/models/customerScreenData',
  'modules/shop.cash-register-retail/collections/currentOrderItem',
  'modules/common/components/locale',
], (
  $, _, Backbone, Template,
  OrderInformation, ImageSlider, QrPayment,
  CustomerScreenDataModel, OrderItemCollection, Locale,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'customer-screen-divider',

  initialize(options) {
    this.shopId = options.shopId;
    this.rightView = null;
  },

  regions: {
    left: '[data-region=left]',
    right: '[data-region=right]',
  },

  onRender() {
    this.renderLeft();
    this.renderRight();
  },

  renderLeft() {
    const region = this.getRegion('left');
    const view = new OrderInformation();
    region.show(view);
  },

  renderRight() {
    const region = this.getRegion('right');

    const expectedView = CustomerScreenDataModel.get('qrInformation')
      ? 'qr' : 'images';
    if (this.rightView !== expectedView || !region.hasView()) {
      let view = null;
      if (expectedView === 'qr') {
        view = new QrPayment({
          headerTitle: Locale.translate('scan_qr_to_pay'),
        });
      } else {
        view = new ImageSlider({
          shopId: this.shopId,
        });
      }
      region.show(view);
      this.rightView = expectedView;
    }
  },

  onShow() {
    CustomerScreenDataModel.on('change:previousOrderId', this.showTick, this);
    CustomerScreenDataModel.on('change:qrInformation', this.renderRight, this);

    OrderItemCollection.on('add', this.hideTick, this);
  },

  onDestroy() {
    CustomerScreenDataModel.off('change:previousOrderId', this.showTick, this);
    CustomerScreenDataModel.off('change:qrInformation', this.renderRight, this);

    OrderItemCollection.off('add', this.hideTick, this);
  },

  ui: {
    tick: '[data-ui=tick]',
  },

  showTick() {
    if (!this.showsTick) {
      this.showsTick = true;
      this.ui.tick.addClass('showed');
      setTimeout(() => {
        this.hideTick();
      }, 30000);
    }
  },

  hideTick() {
    if (this.showsTick) {
      this.showsTick = false;
      if (!this.isDestroyed) this.ui.tick.removeClass('showed');
    }
  },

}));
