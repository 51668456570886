define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/customerScreens/orderScreen/qrPayment.hbs',

  'modules/shop.cash-register-retail/models/customerScreenData',
], (
  $, _, Backbone, Template,
  CustomerScreenData,
) => Backbone.Marionette.LayoutView.extend({

  className: 'qr-payment-page',

  template: Template,

  initialize({ headerTitle = null }) {
    this.loadInformation();
    this.headerTitle = headerTitle;
  },

  loadInformation() {
    const {
      errorMessage = null,
      successMessage = null,
      statusMessage = null,
      qrImageUrl,
      qrOverlayImageUrl = null,
      supportedAppImages = {},
    } = CustomerScreenData.get('qrInformation') || {};
    this.renderData = {
      qrImageUrl,
      qrOverlayImageUrl,
      errorMessage,
      successMessage,
      statusMessage,
      supportedAppImages,
      anyApp: supportedAppImages.length > 0,
    };
  },

  refreshInformation() {
    this.loadInformation();

    if (!this.isDestroyed) {
      this.render();
    }
  },

  onShow() {
    CustomerScreenData.on('change:qrInformation', this.refreshInformation, this);
  },

  onDestroy() {
    CustomerScreenData.off('change:qrInformation', this.refreshInformation, this);
  },

  serializeData() {
    return {
      ...this.renderData,
      headerTitle: this.headerTitle,
    };
  },

}));
