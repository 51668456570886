define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/customerScreens/orderScreen/imageSlider',

  'modules/upx/components/upx',

  'modules/shop.cash-register-retail/models/settings/shopPos',
  'modules/shop.cash-register-retail/models/customerScreenData',
], (
  $, _, Backbone, Template,
  Upx,
  ShopPosSetting, CustomerScreenData,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'customer-screen-image-slider',

  initialize(options) {
    this.shopPos = options.shopPos;
    this.imageCollection = options.galleryItemCollection;
    this.imageQuantity = this.imageCollection.length;

    this.duration = this.shopPos.get('client_image_duration_s') || '5';
    this.effect = this.shopPos.get('client_image_effect') || 'none';
    this.alignment = this.shopPos.get('client_image_alignment') || 'center';
    this.background = this.shopPos.get('client_background_color') || '#ffffff';
  },

  getImages() {
    if (this.imageCollection.length > 0) {
      return this.imageCollection.map((model) => Upx.getResourceUrl(model.get('big_image_url')));
    }

    return [
      CustomerScreenData.getCompanyLogo(),
    ];
  },

  serializeData() {
    return {
      images: this.getImages(),
      effect: this.effect,
      alignment: this.alignment,
      background: this.background,
    };
  },

  ui: {
    images: '[data-ui=images]',
  },

  onShow() {
    this.startSliding();
  },

  onDestroy() {
    clearInterval(this.slider);
  },

  startSliding() {
    // Show the first image
    let index = 0;
    this.showSlide(index);
    index++;

    const duration = parseInt(this.duration) * 1000;

    // interval to go to the next slide
    this.slider = setInterval(() => {
      // show image at a certain index
      // If image was not showable it means it did not found the element
      if (!this.showSlide(index)) {
        // Set the index to 0
        index = 0;
        this.showSlide(index);
      }

      // Up the index
      index++;
    }, duration);
  },

  showSlide(index) {
    // Remove the active and previous-active class from all images
    this.ui.images
      .removeClass('active')
      .removeClass('previous-active');

    // get image element at a certain index
    const image = this.ui.images.get(index);
    if (image) {
      // When found we set it as active and return true
      image.classList.add('active');

      // If there are more then 2 images
      if (this.imageQuantity > 2) {
        // Get the previous index
        let previousIndex = index - 1;
        if (previousIndex < 0) previousIndex = this.imageQuantity - 1; // -1 = offset for 0 based index

        // Add the correct class;
        const previousImage = this.ui.images.get(previousIndex);
        if (previousImage) previousImage.classList.add('previous-active');
      }
      return true;
    }
    // Else we return false
    return false;
  },

}));
